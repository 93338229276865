import React from 'react';

import Page from '../components/Page';
import Footer from '../components/Footer';

import Header from '../components/Header';
import Container from '../components/Container';

import GeneralForm from '../templates/contact/GeneralForm';
import Link from '../components/Link';

function ContactPage({ location }: { location: Location }) {
  return (
    <Page
      location={location}
      title="Contact Us"
      description="Get in touch with us and let us know how we can help."
      header={<Header search={false} />}
    >
      <div className="flex flex-col items-center justify-center bg-gray-900 text-white py-16">
        <h1 className="text-5xl font-hairline">Contact Us</h1>
        <p className="text-2xl text-center font-thin">
          We are here to help.
          <br /> Get in touch with us for support or questions about Notfiee.
        </p>
      </div>
      <Container size="xs">
        <div className="my-16 px-6">
          <div>
            <h2 className="text-3xl">General Enquires</h2>
            <p className="text-base mb-8">
              If you have any general enquires about Notifee please get in touch below. If you have
              any bug reports or issues with the library itself, please create an issue over on{' '}
              <Link to="https://github.com/invertase/notifee">GitHub</Link>.
            </p>
            <GeneralForm />
          </div>
        </div>
      </Container>
      <Footer />
    </Page>
  );
}

export default ContactPage;
