import * as React from 'react';
import a2a from 'a2a';
import axios from 'axios';
import serialize from 'form-serialize';
import { Button } from '@invertase/ui';

import Form from '../../components/Form';

type Errors = { [key: string]: string };

function FormTemplate() {
  const formRef = React.createRef<HTMLFormElement>();
  const [errors, setErrors] = React.useState<Errors>({});
  const [sent, setSent] = React.useState<boolean>(false);

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');

  async function submit() {
    const e: Errors = {};

    if (!name) e.name = 'Please enter a name';
    if (!email) e.email = 'Please enter an email address';
    if (!message) e.message = 'Please enter a message';
    if (message && message.length < 10) e.message = 'Please enter a longer message';

    if (Object.keys(e).length) {
      setErrors(e);
      return;
    }

    setErrors({});

    // @ts-ignore
    const { action } = formRef.current;

    const [error] = await a2a(
      // @ts-ignore
      axios.post(action, serialize(formRef.current), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }),
    );

    if (error) {
      alert('Sorry, something went wrong submitting your message. Please try again.');
    } else {
      setSent(true);
    }
  }

  if (sent) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <p className="text-center">
          Thanks, your message has been sent. <br /> We aim to respond to all messages within 3
          working days.
        </p>
      </div>
    );
  }

  return (
    <Form.Netlify name="contact" action="/contact" reference={formRef}>
      <Form.Group>
        <Form.Label for="name">Name</Form.Label>
        <Form.Input id="name" value={name} onChangeText={setName} />
        <Form.Error message={errors.name} visible={!!errors.name} />
      </Form.Group>
      <Form.Group>
        <Form.Label for="email-github">Email</Form.Label>
        <Form.Input id="email-github" value={email} onChangeText={setEmail} />
        <Form.Error message={errors.email} visible={!!errors.email} />
      </Form.Group>
      <Form.Group>
        <Form.Label for="message">Message</Form.Label>
        <Form.TextArea id="message" value={message} onChangeText={setMessage} />
        <Form.Error message={errors.message} visible={!!errors.message} />
      </Form.Group>
      <Form.Group>
        <Button ghost onClick={() => submit()}>
          Send Message
        </Button>
        <p className="text-base text-gray-600 dark:text-gray-400">
          We aim to respond to all emails within 7 working days. By contacting us you agree that we
          may contact you on your provided email address for the purpose of responding to your
          query.
        </p>
      </Form.Group>
    </Form.Netlify>
  );
}

export default FormTemplate;
